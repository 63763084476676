import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

const swiper = new Swiper('.swiper', {
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
});

const indexSwiper = new Swiper('.indexSwiper', {
    loop: true,
    slidesPerView: .6,
    speed: 25000,
    allowTouchMove: false,
    autoplay: {
        delay: 0,
    },
    centeredSlides: true,
    breakpoints: {
        640: {
            slidesPerView: 1.5,
        }
    }
});

// const swiper2 = new Swiper('.swiper2', {
//     // Optional parameters
//     direction: 'vertical',
//     loop: true,

//     // If we need pagination
//     pagination: {
//         el: '.swiper-pagination',
//     },

//     // Navigation arrows
//     navigation: {
//         nextEl: '.swiper-button-next',
//         prevEl: '.swiper-button-prev',
//     },

//     // And if we need scrollbar
//     scrollbar: {
//         el: '.swiper-scrollbar',
//     },
// });

const trigger = document.querySelectorAll(".recipe_item[data-target]")

// dialogの発火イベント
trigger.forEach(item => {
    item.addEventListener("click", event => {
        const target = event.currentTarget.dataset.target;
        const targetEl = document.getElementById(target);
        targetEl.showModal();
    })
})

// dialogのcloseイベント
const btnClose = document.querySelectorAll('.btn_close');
btnClose.forEach(btn => {
    btn.addEventListener( 'click', event => {
        event.currentTarget.parentNode.parentNode.close();
    }, false );
})

const dialogs = document.querySelectorAll('dialog');
dialogs.forEach(dialog => {
    dialog.addEventListener( 'click', event => {
        event.currentTarget.close();
    }, false );
    
    const contents = dialog.querySelector( '.dialog_inner' );
    contents.addEventListener( 'click', event => {
        event.stopPropagation();
    }, false );
})

